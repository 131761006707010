
const accordions = document.querySelectorAll('.accordion__panel');
const accordionButtons = document.querySelectorAll('.accordion__panel__button');
const accordionContent = document.querySelectorAll('.accordion__panel__content');

accordionButtons.forEach((button)=>{
    button.addEventListener('click', ()=>{
        let Maxheight = button.parentElement.getAttribute('data-height')

        if (button.getAttribute('aria-expanded') == 'true') {
            let sib = button.nextElementSibling;
            sib.style.height = '0px';
            accordionButtons.forEach((btn)=>{
                btn.setAttribute('aria-expanded', false);
                
            }); 
        } else {
            
            accordionContent.forEach((item)=>{
                item.style.height = '0px';
            });
            accordionButtons.forEach((btn)=>{
                btn.setAttribute('aria-expanded', false);
                
            });
            // button.parentElement.setAttribute('data-accordionisOpen', true);
            button.setAttribute('aria-expanded', true);
            let sib = button.nextElementSibling;
            sib.style.height = Maxheight + 'px';
            
        }

    });
});