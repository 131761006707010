gsap.registerPlugin(ScrollTrigger);

const animated = document.querySelectorAll('[data-canAnimate]');

if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches){
    addAnimation();
    
    const heroBlock = document.querySelector('.accent-block');
    const heroBtn = document.querySelector('.button-hero');
    const navlinks = gsap.utils.toArray('.slide-in');
    const howItWorksSection = document.querySelector('#howItWorks');
    const cardInfo = gsap.utils.toArray('.card-info--orange');

    const slideInFromLeft = gsap.utils.toArray('.slide-in-from-left')
    const slideInFromRight = gsap.utils.toArray('.slide-in-from-right')
    const slideInFromBottom = gsap.utils.toArray('.slide-in-from-bottom')
    // const slideInFromLeft = gsap.utils.toArray('.slide-in-from-left')

    console.log(slideInFromRight[0].closest('section'));

    const cardBlack = gsap.utils.toArray('.card-info--black');

    const benefitsSection = document.querySelector('.benefits-inner--wrapper');

    let mm = gsap.matchMedia();

    mm.add('(min-width: 48rem)', ()=>{

        gsap.from(heroBlock, {
            x:'-200%',
            duration:2
        })

        gsap.to(heroBlock, {
            skewX: 20,
            duration:1,
            delay:1.5
        })

        gsap.from(heroBtn,{
            y:'150px',
            opacity:0,
            duration:1,
            delay:1
        })

        gsap.from(navlinks,{
            y: '-200px',
            opacity:0,
            stagger:0.3,
            delay: 1
        })

        gsap.from(cardInfo,{
            yPercent:100,
            stagger:0.4,
            opacity:0,
            duration:0.7,
            scrollTrigger:{
                trigger:howItWorksSection,
                start:'-20% top'
            }
        })

        slideInFromLeft.forEach((element) => {
            gsap.from(element,{
                xPercent:-100,
                opacity:0,
                duration:1,
                stagger:1,
                scrollTrigger:{
                    trigger: element.closest('section'),
                    start:'-10% top'
                }
            })
        });
        slideInFromRight.forEach((element) => {
            gsap.from(element,{
                xPercent:100,
                opacity:0,
                duration:1,
                stagger:1,
                scrollTrigger:{
                    trigger: element.closest('section'),
                    start:'-10% top'
                }
            })
        });
        gsap.from(slideInFromBottom,{
            yPercent:100,
            opacity:0,
            duration:1,
            scrollTrigger:{
                trigger:document.querySelector('#pricing'),
                start:'-10% top'
            }
        })

        gsap.from(benefitsSection, {
            yPercent:110,
            duration:1,
            scrollTrigger:{
                trigger: document.querySelector('#benefits'),
                start:'-10% top'
            }
        })

        gsap.from(cardBlack,{
            opacity:0,
            yPercent:100,
            duration:1,
            delay:0.5,
            stagger:0.4,
            scrollTrigger:{
                trigger: document.querySelector('#benefits'),
                start:'-10% top'
            }
        })
    });






}


function addAnimation(){
    animated.forEach(element => {
        element.setAttribute('data-animated', true);       
        
    });
}